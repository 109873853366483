import star from '../assets/Star.svg'; 
import shield from '../assets/Shield.svg';
import send from '../assets/Send.svg';

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Personalized Services",
    content:
      "We deliver customized business solutions for clients and individuals establishing a presence in the UAE.",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Expert Guidance",
    content:
      "Our team supports you through every step of company formation, covering all essential details smoothly.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Ongoing Support",
    content:
      "We provide continuous assistance in accounting, marketing, and HR, ensuring your business flourishes.",
  },
];

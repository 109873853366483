import React, { useState, useEffect } from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styles from "./NavbarMain.module.css";

const NavbarMain = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeNav, setActiveNav] = useState([true, false, false, false]);
  const [expand, setExpand] = useState(false);

  const closeNav = () => setExpand(false);

  const handleActiveNav = (i) => {
    const newActiveNav = activeNav.map((_, index) => index === i);
    setActiveNav(newActiveNav);
    sessionStorage.setItem("NavbarMain", JSON.stringify(newActiveNav));
  };

  const toggleDropdown = () => setShowDropdown((prev) => !prev);

  useEffect(() => {
    const storedNavState = sessionStorage.getItem("NavbarMain");
    if (storedNavState) {
      setActiveNav(JSON.parse(storedNavState));
    }
  }, []);

  return (
    <Navbar
      style={{
        backgroundColor: "white",
        boxShadow: "1px 1px 10px rgb(0 0 0 / 0.4)",
      }}
      variant="light"
      expand="lg"
      sticky="top"
      onToggle={() => setExpand((prev) => !prev)}
      expanded={expand}
    >
      <Container>
        <div className={styles.logoContainer}>
          <a href="https://vynxgroup.com/">
            <img
              src={require("../../assets/Company logo.jpg")}
              alt="VYNX Logo"
              className={styles.logoImage}
            />
          </a>
          <Navbar.Brand href="https://vynxgroup.com/" className={styles.logo}>
            VYNX
            <div className={styles.subLogo}>Business Formation</div>
          </Navbar.Brand>
        </div>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav style={{ marginLeft: "auto" }}>
            <NavLink
              to="/"
              className={`${styles.nav_text} nav-link ${
                activeNav[0] ? styles.active : ""
              }`}
              style={{ marginTop: "8px" }}
              onClick={() => {
                handleActiveNav(0);
                closeNav();
              }}
            >
              Home
            </NavLink>

            <NavLink
              to="/aboutUs"
              className={`${styles.nav_text} nav-link ${
                activeNav[1] ? styles.active : ""
              }`}
              style={{ marginTop: "8px" }}
              onClick={() => {
                handleActiveNav(1);
                closeNav();
              }}
            >
              About us
            </NavLink>

            <NavDropdown
              show={showDropdown}
              onClick={toggleDropdown}
              className={`nav-link ${styles.drop}`}
              title={
                <NavLink
                  to="/services"
                  style={{ textDecoration: "none" }}
                  className={styles.dropicon}
                  onClick={() => {
                    handleActiveNav(2);
                    closeNav();
                  }}
                >
                  <span
                    className={`${styles.nav_text} my-auto ${
                      activeNav[2] ? styles.active : ""
                    }`}
                  >
                    Services
                  </span>
                </NavLink>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item className={styles.dropdownItem}>
                <NavLink
                  to="/individualService?id=1"
                  onClick={() => {
                    handleActiveNav(2);
                    closeNav();
                  }}
                  className={styles.dropdownText}
                >
                  License
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropdownItem}>
                <NavLink
                  to="/individualService?id=2"
                  onClick={() => {
                    handleActiveNav(2);
                    closeNav();
                  }}
                  className={styles.dropdownText}
                >
                  Visa
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropdownItem}>
                <NavLink
                  to="/individualService?id=3"
                  onClick={() => {
                    handleActiveNav(2);
                    closeNav();
                  }}
                  className={styles.dropdownText}
                >
                  Finance and Banking
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropdownItem}>
                <NavLink
                  to="/individualService?id=4"
                  onClick={() => {
                    handleActiveNav(2);
                    closeNav();
                  }}
                  className={styles.dropdownText}
                >
                  Citizenship Program
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropdownItem}>
                <NavLink
                  to="/individualService?id=5"
                  onClick={() => {
                    handleActiveNav(2);
                    closeNav();
                  }}
                  className={styles.dropdownText}
                >
                  VIP Medical Test
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.dropdownItem}>
                <NavLink
                  to="/individualService?id=6"
                  onClick={() => {
                    handleActiveNav(2);
                    closeNav();
                  }}
                  className={styles.dropdownText}
                >
                  Customs & Product Registration
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>

            <NavLink
              to="/contactUs"
              className={`${styles.nav_text} nav-link ${
                activeNav[3] ? styles.active : ""
              }`}
              style={{ marginTop: "8px" }}
              onClick={() => {
                handleActiveNav(3);
                closeNav();
              }}
            >
              Contact Us
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarMain;

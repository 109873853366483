import React, { useEffect, useState } from "react";
import styles from "./IndividualService.module.css";
import { useLocation } from "react-router-dom";
import data from "./data";

const IndividualService = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { search } = useLocation();
  const id = parseInt(new URLSearchParams(search).get("id"));
  let service = data.filter((x) => x.id === id)[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
  };

  return (
    <div className={`container ${styles.contain} overflow-hidden`}>
      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <button
              className={styles.closeIcon}
              onClick={() => setIsModalOpen(false)}
            >
              &times;
            </button>
            <h2 className={styles.modalTitle}>
              Get a Callback within 60 minutes
            </h2>

            <form onSubmit={handleSubmit}>
              <div
                className={styles.modalField}
                style={{ display: "flex", gap: "20px" }}
              >
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  required
                  className={styles.modalInput}
                  placeholder="First Name"
                />
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  required
                  className={styles.modalInput}
                  placeholder="Last Name"
                />
              </div>

              <div className={styles.modalField}>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  pattern="^[0-9]{10}$"
                  required
                  className={styles.modalInput}
                  placeholder="Phone Number"
                />
              </div>
              <div className={styles.modalField}>
                <input
                  type="text"
                  id="nationality"
                  name="nationality"
                  required
                  className={styles.modalInput}
                  placeholder="Nationality"
                />
              </div>
              <div className={styles.modalField}>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  className={styles.modalInput}
                  placeholder="Email Address"
                />
              </div>
              <button type="submit" className={styles.submitButton}>
                Submit
              </button>
            </form>
          </div>
        </div>
      )}

      <p data-aos="fade-up" className={styles.heading}>
        {service.heading}
      </p>
      <p data-aos="fade-up" className={styles.heading_content}>
        {service.content}
      </p>

      {service.contents.map((x, idx) =>
        idx % 2 === 0 ? (
          <div key={idx} className={`row ${styles.rowSpacing}`}>
            <div
              data-aos="fade-right"
              data-aos-offset="150"
              className="col-md-6"
            >
              {x.img1 && (
                <img src={x.img1} alt="" className={styles.contentImg} />
              )}
              {x.img2 && (
                <img src={x.img2} alt="" className={styles.contentImg} />
              )}
            </div>

            <div
              data-aos="fade-left"
              data-aos-offset="150"
              className="col-md-6"
            >
              <p className={`${styles.subHead}`}>{x.heading}</p>
              <p className={`${styles.heading_content} ${styles.justify}`}>
                {x.content}
              </p>
              <div className={styles.explanation}>
                {x.contentexplination.split("\n").map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div key={idx} className={`row ${styles.rowSpacing}`}>
            <div
              className="col-md-6"
              data-aos="fade-right"
              data-aos-offset="150"
            >
              <p className={`${styles.subHead}`}>{x.heading}</p>
              <p className={`${styles.heading_content} ${styles.justify}`}>
                {x.content}
              </p>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-left"
              data-aos-offset="150"
            >
              <img src={x.img} alt="" className={styles.contentImg} />
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default IndividualService;

import React from "react";
import styles from "./Real.module.css";
import improveImg from "../../assets/home/resource.png";

const Real = () => {
  return (
    <div className="mb-5 overflow-hidden">
      <div className="row g-0">
        
        <div className="col-md-6">
          <div className={styles.text}>
            <p
              data-aos="slide-right"
              data-aos-offset="150"
              className={styles.heading}
            >
              Resources
            </p>
            <p
              data-aos="slide-right"
              data-aos-offset="150"
              className={styles.subHeading}
            >
              Enhance Your Knowledge.
            </p>
            <p
              data-aos="zoom-in"
              data-aos-offset="150"
              className={styles.content}
            >
              Discover a wide range of resources including in-depth articles,
              comprehensive guides, interactive tools, and expert insights
              designed to empower you with knowledge. Our resources cover
              diverse topics, from investment strategies and financial planning
              tips to property management best practices and real estate market
              trends. Whether you're new to investing or a seasoned
              professional, you’ll find actionable advice, real-world examples,
              and data-driven insights to help you make informed decisions.
              Explore step-by-step guides, expert-recommended strategies, and
              the latest market insights to stay ahead in today’s dynamic
              landscape. Dive into our resources to gain the clarity and
              confidence needed to achieve your goals.{" "}
            </p>
            <div data-aos="zoom-in" style={{ textAlign: "left" }}>
            </div>
          </div>
        </div>

        <div className={`col-md-6 ${styles.imgDiv}`}>
          <img
            data-aos="slide-left"
            data-aos-offset="150"
            src={improveImg}
            alt=""
            className={styles.img}
          />
        </div>
      </div>
    </div>
  );
};
export default Real;

import React from "react";
import styles from "./Middle.module.css";

const Middle = () => {
  return (
    <div style={{ backgroundColor: "#f8f8f8" }} className="overflow-hidden">
      <div className={`container ${styles.contain}`}>
        <p
          data-aos="fade-down-right"
          data-aos-offset="170"
          className={styles.mainHead}
        >
          What We Offer
        </p>
        <p
          data-aos="fade-up-left"
          data-aos-offset="170"
          className={styles.mainContent}
        >
          VYNX provides end-to-end business setup services, including:
        </p>

        <div className="row">
          <div data-aos="fade-right" data-aos-offset="170" className="col-md-4">
            <div className={styles.box}>
              <p className={styles.head}>Business Licensing</p>
              <p className={styles.content}>
                Assistance with obtaining the right license for your business to
                ensure legal compliance.
              </p>
            </div>
          </div>

          <div data-aos="fade-up" className="col-md-4">
            <div className={styles.box}>
              <p className={styles.head}>Visa Services</p>
              <p className={styles.content}>
                Support with visa applications and renewals for you, your team,
                and your family members.
              </p>
            </div>
          </div>

          <div data-aos="fade-left" className="col-md-4">
            <div className={styles.box}>
              <p className={styles.head}>Finance and Banking</p>
              <p className={styles.content}>
                Guidance in setting up corporate bank accounts and managing
                finance options that suit your business.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div data-aos="fade-right" className="col-md-4">
            <div className={styles.box}>
              <p className={styles.head}>Citizenship Programs</p>
              <p className={styles.content}>
                Advisory services for those seeking citizenship options aligned
                with business needs.
              </p>
            </div>
          </div>

          <div data-aos="fade-up" className="col-md-4">
            <div className={styles.box}>
              <p className={styles.head}>VIP Medical Tests</p>
              <p className={styles.content}>
                Streamlined medical testing services for visa and other
                requirements, ensuring a smooth experience.
              </p>
            </div>
          </div>

          <div data-aos="fade-left" className="col-md-4">
            <div className={styles.box}>
              <p className={styles.head}>Customs and Product Registration</p>
              <p className={styles.content}>
                Help with customs registration and product approvals to
                facilitate smooth import and export processes.
              </p>
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div data-aos="fade-right" className="col-md-4">
            <div className={styles.box}>
              <p className={styles.head}>Office Space Solutions</p>
              <p className={styles.content}>
                Assistance in locating and securing office spaces suited to your
                business needs, from flexible workspaces to long-term leases.
              </p>
            </div>
          </div>
        </div> */}

        <p
          data-aos="fade-up"
          className={styles.mainContent}
          style={{ textAlign: "center", marginTop: "30px" }}
        >
          At VYNX, we are here to help you build and grow your business with
          trusted expertise, industry knowledge, and unwavering support. Contact
          us today to learn how we can help bring your vision to life in the
          UAE.
        </p>
      </div>
    </div>
  );
};

export default Middle;

import React from "react";
import styles from "./Services.module.css";
import ServiceHome from "../ServiceHome/ServiceHome";
import data from "./data";

import serviceImage from "../../assets/Services.png";

const Services = () => {
  return (
    <div className={`container ${styles.contain} overflow-hidden pb-2`}>
      <p data-aos="slide-right" className={styles.heading}>
        Our Services
      </p>

      <div className={styles.serviceImageContainer}>
        <img
          src={serviceImage}
          alt="Our Services"
          className={styles.serviceImage}
        />
      </div>

      <div className={`row ${styles.cont}`}>
        {data &&
          data.map((x) => (
            <div key={x.id} className="col-lg-4 col-md-6">
              <ServiceHome
                id={x.id}
                heading={x.heading}
                content={x.content}
                to={x.to}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Services;

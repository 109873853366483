import licence from "../../assets/individualService/licence.jpg";
import secondLicenceImg from "../../assets/individualService/licence2.jpg";
import visa from "../../assets/individualService/visa.jpg";
import visa1 from "../../assets/individualService/visa1.jpg";
import banking from "../../assets/individualService/banking.jpg";
import banking2 from "../../assets/individualService/banking2.jpg";
import cit from "../../assets/individualService/cit.jpg";
import test from "../../assets/individualService/test.jpg";
import id from "../../assets/individualService/id.jpg";
import reg from "../../assets/individualService/reg.jpg";
import reg1 from "../../assets/individualService/reg1.jpg";

const data = [
  {
    id: 1,
    heading: "License",
    content:
      "We assist with obtaining and managing business licenses, from new applications to renewals, modifications, and cancellations, ensuring compliance with local and international standards.",
    to: "/individualService?id=1",
    contents: [
      {
        heading: "Our License Services",
        img1: licence,
        img2: secondLicenceImg,
        content:
          "We provide a wide range of license services to ensure your business is always compliant and operational. Whether it's renewing, modifying, freezing, or canceling your licenses, we ensure that each process is handled efficiently and in compliance with the relevant regulations. Our services are designed to provide fast, reliable, and cost-effective solutions for businesses of all sizes.",
        contentexplination:
          "Our services include:\n" +
          "1. License Renewal – We assist with timely renewals to keep your business compliant.\n" +
          "2. License Modification – Make necessary modifications to your licenses as per new requirements.\n" +
          "3. License Freezing – Temporarily freeze your licenses for specific purposes.\n" +
          "4. License Cancellation – We help manage the process of canceling licenses with minimal hassle.\n" +
          "5. New Business Licensing – We guide new businesses in obtaining the proper licenses from the start.\n" +
          "6. Regulatory Advice – Receive guidance on how to comply with industry-specific regulations.",
      },
    ],
  },

  {
    id: 2,
    heading: "Visa",
    content:
      "We offer visa services including residence, dependent, remote work, golden, and freelance visas. We ensure smooth processing and proper documentation for fast approval.",
    to: "/individualService?id=2",
    contents: [
      {
        heading: "Our Visa Services",
        img1: visa,
        img2: visa1,
        content:
          "A work visa lets you live and work in a country based on your employment. Whether you're coming for short-term projects or long-term work, we provide expert assistance in securing the right work visa for your career, helping you comply with all immigration requirements. Our team ensures that the entire visa application process is handled smoothly, from document collection to final approval.",
        contentexplination:
          "Our services include:\n" +
          "1. Residence & Dependent Visa – Secure the right to live and bring family members with you.\n" +
          "2. Remote Work Visa – For digital nomads and remote workers looking to live abroad.\n" +
          "3. Golden Visa – Invest and secure residency in multiple countries with long-term benefits.\n" +
          "4. Freelance Visa – Designed for independent contractors and entrepreneurs seeking legal work authorization.\n" +
          "5. Tourist & Business Visas – Get short-term visas for personal or professional travel.\n" +
          "6. Visa Extensions – Extend your visa when necessary, following all regulations.",
      },
    ],
  },

  {
    id: 3,
    heading: "Finance and Banking",
    content:
      "Our finance services help manage business and personal finances, from opening accounts to securing loans, ensuring effective financial solutions for growth.",
    to: "/individualService?id=3",
    contents: [
      {
        heading: "Our Services",
        img1: banking,
        img2: banking2,
        content:
          "Opening a business bank account is a critical step for any entrepreneur. We guide you through the process of choosing the right bank, preparing the necessary documentation, and meeting all legal requirements. Our goal is to make this process seamless so you can focus on growing your business. We also help businesses with financial planning, debt management, and setting up corporate accounts that support long-term goals.",
        contentexplination:
          "Our services include:\n" +
          "1. Bank Account Opening – Expert advice on selecting the best bank and account type.\n" +
          "2. Corporate Tax Guide – Tailored solutions for your tax compliance and financial obligations.\n" +
          "3. Bookkeeping & VAT Registration – Ensure accurate record-keeping and proper VAT registration for your business.\n" +
          "4. Loan and Financing Assistance – Get help securing loans and financing for business expansion.\n" +
          "5. Investment Planning – Optimize your business investments for maximum returns.\n" +
          "6. Business Taxation Advice – Avoid common tax pitfalls and plan effectively for tax season.",
      },
    ],
  },

  {
    id: 4,
    heading: "Citizenship Program",
    to: "/individualService?id=4",
    content:
      "We guide you through acquiring a second citizenship, offering pathways such as investment citizenship, providing greater mobility and security.",
    contents: [
      {
        heading: "Our Citizenship services",
        img1: cit,
        content:
          "Our UK Citizenship Program provides a streamlined process for those looking to gain British nationality. With a focus on residency and investment opportunities, we guide you through the entire process to secure your path to citizenship and enjoy the benefits of living and working in the UK. We also offer citizenship programs for other countries, providing you with various options depending on your personal goals and investment preferences.",
        contentexplination:
          "Our services include:\n" +
          "1. UK Citizenship Program – Secure British nationality through investment or residency.\n" +
          "2. Innovator Residency Visa – A route to residency for entrepreneurs and innovators in the UK.\n" +
          "3. Tier 2 Skilled Worker Visa – Gain residency through skilled work opportunities in the UK.\n" +
          "4. Other Citizenship by Investment Programs – Explore opportunities in various countries for second citizenship.\n" +
          "5. Family Residency Programs – Secure citizenship for you and your family members.",
      },
    ],
  },

  {
    id: 5,
    heading: "VIP Medical Test",
    content:
      "We provide VIP medical checkups with comprehensive exams and prompt results, ensuring top-tier healthcare and privacy for personal health or travel needs.",
    to: "/individualService?id=5",
    contents: [
      {
        heading: "VIP Medical Test & Emirates ID",
        img1: test,
        img2: id,
        content:
          "To apply for your visa and Emirates ID, undergoing a medical test in the UAE is a mandatory step. This involves various processes, such as paperwork and scheduling an appointment. We offer a comprehensive VIP Medical Service to assist you every step of the way. From managing all the necessary paperwork to securing your appointment, we handle everything for you.\n\n" +
          "Our service goes beyond just paperwork; we also provide pickup and drop-off services for both you and your passport, ensuring a smooth, stress-free experience. We'll escort you through the process, ensuring everything runs seamlessly and efficiently.\n\n" +
          "By utilizing this service, not only will the procedure be simplified, but it will also be completed in a much faster and more convenient manner.",
        contentexplination:
          "Our services include:\n" +
          "1. Medical Test Scheduling – We coordinate and schedule your medical tests at convenient times.\n" +
          "2. Paperwork Management – We handle all documentation to ensure everything is in order.\n" +
          "3. Pickup and Drop-off Services – We provide full-service transport for you and your documents.\n" +
          "4. Fast and Convenient Processing – We ensure your results are delivered quickly and securely.\n" +
          "5. Pre-Travel Medical Tests – Get tested and cleared for travel with our expedited service.\n" +
          "6. Health Management – Comprehensive health checkups for preventive care.",
      },
    ],
  },

  {
    id: 6,
    heading: "Product & Customs Registration",
    content:
      "We streamline product registration with authorities, handling customs documentation and legal compliance for smooth and efficient trade clearance.",
    to: "/individualService?id=6",
    contents: [
      {
        heading: "Registration Services",
        img1: reg,
        img2: reg1,
        content:
          "We provide expert services to help businesses register their products with the appropriate authorities. Our services cover everything from product classification to ensuring compliance with local and international laws. Whether you're entering a new market or introducing new products, we make sure you meet all necessary regulatory requirements, reducing the time to market and avoiding potential issues down the line.",
        contentexplination:
          "Our services include:\n" +
          "1. Product Classification – Determine the correct classification for your product to comply with import/export laws.\n" +
          "2. Regulatory Compliance – Ensure your products meet local and international regulations.\n" +
          "3. Customs Documentation – Manage all paperwork and documentation for customs registration.\n" +
          "4. Import/Export Support – We assist with the logistics and regulatory aspects of importing and exporting your products.\n" +
          "5. Customs Clearance – Ensure timely customs clearance for your goods.\n" +
          "6. International Standards – We guide you in maintaining global compliance across markets.",
      },
    ],
  },
];
export default data;

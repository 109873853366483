import React from "react";
import styles from "./AboutUsHome.module.css";
import { features } from "../../content/content";

const FeatureCard = ({ icon, title, content, index }) => (
  <div
    className={`${styles.featureCard} ${
      index !== features.length - 1 ? styles.mb6 : ""
    }`}
    data-aos={index % 2 === 0 ? "fade-up" : "fade-down"}
    data-aos-offset="100"
    data-aos-duration="600"
  >
    <div className={`${styles.iconContainer}`}>
      <img src={icon} alt="feature icon" className={styles.icon} />
    </div>

    <div className={styles.contentContainer}>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.content}>{content}</p>
    </div>
  </div>
);

const AboutUsHome = () => {
  return (
    <div className={`container ${styles.contain}`}>
      <div className="row overflow-hidden">
        <div className="col-md-6">
          <p data-aos="slide-right" className={styles.head}>
            ABOUT US!
          </p>
          <p data-aos="slide-left" className={styles.heading}>
            We have a great team in terms of technology
          </p>
          <div data-aos="zoom-in">
            <p className={styles.content}>
              At VYNX, we offer personalized business setup services for clients
              establishing companies in the UAE. Our dedicated team guides you
              through corporate structure, jurisdiction, and all aspects of
              business setup, including ongoing support with accounting,
              marketing, and HR.
            </p>
            <p className={styles.content}>
              Our mission is to streamline company formation and support
              entrepreneurs with our expertise in IT, Telecom, Education, and
              Company Formation services, ensuring tailored solutions that
              promote growth and lasting client relationships.
            </p>
          </div>
        </div>

        {/* Feature Cards Section */}
        <div className="col-md-6">
          <div className={styles.featuresContainer}>
            {features.map((feature, index) => (
              <FeatureCard key={feature.id} {...feature} index={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsHome;

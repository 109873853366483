import img1 from "../../../assets/aboutUs/bottom2.jpg";
import img2 from "../../../assets/aboutUs/us.jpg";

const fakeData = [
  {
    id: 1,
    heading: "Our Mission at VYNX",
    img: img1,
    content:
      "Our mission at VYNX is to simplify the company formation process and empower entrepreneurs and businesses to succeed. We are committed to leveraging our in-depth knowledge and resources in IT, Telecom, Education, and Company Formation services to deliver tailored solutions that foster growth, innovation, and enduring client relationships. We strive to be a reliable partner for each stage of your business journey, helping you thrive in the dynamic UAE market.",
  },
  {
    id: 2,
    heading: "Why Choose Us?",
    img: img2,
    content:
      "VYNX provides end-to-end business setup services that include company formation, visa services, finance and banking solutions, citizenship programs, and office space solutions. We support entrepreneurs with personalized services that address every aspect of setting up and growing their business in the UAE. Our expert team offers tailored solutions to ensure compliance and operational success from day one.",
  },
];

export default fakeData;

import React from "react";
import styles from "./ServicesHome.module.css";
import bill from "../../assets/Services.png";

const ServicesHome = () => {
  return (
    <div className={`container ${styles.contain} overflow-hidden pb-4`}>
      <p data-aos="slide-right" className={styles.heading}>
        Services
      </p>
      <div>
        <p data-aos="slide-left" className={`mx-auto ${styles.subHeading}`}>
          Our services
        </p>
      </div>
      <div className={styles.serviceContainer}>
        <div className={styles.imageContainer}>
          <img
            data-aos="slide-right"
            src={bill}
            alt="Services"
            className={styles.image}
          />
        </div>

        <div className={styles.contentContainer}>
          <p data-aos="slide-left" className={styles.contentHeading}>
            At VYNX, we provide a range of personalized business setup services
            for corporate clients and individuals looking to establish their
            presence in the UAE. Our expert team is dedicated to guiding you
            through every step of the process.From corporate structure advice to
            ongoing support in accounting, marketing, and HR, we ensure that
            your business is set up for success. Let us help you navigate the
            complexities of starting a business in the UAE.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesHome;

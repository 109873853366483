import React, { useState } from "react";
import emailjs from "emailjs-com";
import styles from "./ContactUsForm.module.css";

const ContactUsForm = () => {
  const [formContent, setFormContent] = useState({
    name: "",
    number: "",
    email: "",
    subject: "",
    message: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleChange = (event) => {
    const { value, name } = event.target;
    setFormContent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form data being sent:", formContent);
    emailjs
      .send(
        "service_6fmrxe3",
        "template_nd7az28",
        {
          name: formContent.name,
          number: formContent.number,
          email: formContent.email,
          subject: formContent.subject,
          message: formContent.message,
        },
        "0CpcWvGNV5HgZ9mFn"
      )
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);
        setShowSuccessPopup(true);
        setFormContent({
          name: "",
          number: "",
          email: "",
          subject: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        alert("Failed to send email. Please check the console for details.");
      });
  };

  const handleClosePopup = () => {
    setShowSuccessPopup(false);
  };

  return (
    <>
      <div className={`container ${styles.contain} overflow-hidden`}>
        <p data-aos="fade-up" className={`${styles.heading} mx-auto`}>
          We'd Love to Hear From You
        </p>

        <div className="row">
          <div className="col-md-4" data-aos="fade-right">
            <p className={styles.head}>Address</p>
            <p className={styles.content}>Armenia 🇦🇲, Yerevan</p>
          </div>

          <div className="col-md-4" data-aos="fade-up" data-aos-offset="100">
            <p className={styles.head}>Contact</p>
            <p className={styles.content}>
              Mobile:{" "}
              <a href="tel:+919177547901" className={styles.link}>
                +91 9177547901
              </a>{" "}
              ,{" "}
              <a href="tel:+37491757677" className={styles.link}>
                +374 91 757677
              </a>
            </p>
            <p className={styles.content}>
              Email:{" "}
              <a href="mailto:info@vynxglobal.com" className={styles.link}>
                info@vynxglobal.com
              </a>
            </p>
          </div>

          <div className="col-md-4" data-aos="fade-left" data-aos-offset="70">
            <p className={styles.head}>Availability</p>
            <p className={styles.content}>24/7</p>
          </div>
        </div>

        <p className={styles.email} data-aos="fade-up">
          You can email us
        </p>

        <form
          className={`${styles.formContainer} mb-4`}
          onSubmit={handleSubmit}
          data-aos="fade-up"
        >
          <div className="row">
            <div
              className="col-md-6"
              data-aos="fade-right"
              style={{ textAlign: "left" }}
            >
              <label className={styles.label}>Your Name</label>
              <br />
              <input
                name="name"
                value={formContent.name}
                onChange={handleChange}
                className={styles.input}
                type="text"
                required
              />

              <label className={styles.label}>Your Number</label>
              <br />
              <input
                name="number"
                value={formContent.number}
                onChange={handleChange}
                className={styles.input}
                type="text"
                required
              />

              <label className={styles.label}>Your Email</label>
              <br />
              <input
                name="email"
                value={formContent.email}
                onChange={handleChange}
                className={styles.input}
                type="email"
                required
              />

              <label className={styles.label}>Subject</label>
              <br />
              <input
                name="subject"
                value={formContent.subject}
                onChange={handleChange}
                className={styles.input}
                type="text"
                required
              />
            </div>

            <div
              className="col-md-6"
              data-aos="fade-left"
              style={{ textAlign: "left" }}
            >
              <label className={styles.label}>Message</label>
              <br />
              <textarea
                name="message"
                value={formContent.message}
                onChange={handleChange}
                className={`${styles.input} ${styles.msg}`}
                required
              />
              <div style={{ textAlign: "center" }}>
                <button
                  type="submit"
                  className={`btn custom_btn ${styles.btn}`}
                >
                  SEND
                </button>
              </div>
            </div>
          </div>
        </form>

        {/* Success Popup */}
        {showSuccessPopup && (
          <div className={styles.popup}>
            <div className={styles.popupContent}>
              <h2>Success!</h2>
              <p>Your message has been sent successfully.</p>
              <button onClick={handleClosePopup} className={styles.closeBtn}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ContactUsForm;

import React from "react";
import styles from './Banner.module.css';

const Banner = () => {
  return (
    <div className={styles.imgbox}>
      <div className={`${styles.center} overflow-hidden`}>
        {/* Main Heading */}
        <p data-aos="fade-down" className={styles.text}>VYNX Global Business Formation</p>
        
        {/* Subheading */}
        <h2 data-aos="fade-up" className={styles.subtext}>
          Your one-stop solution for company formation
        </h2>
        
        {/* Description */}
        <p data-aos="fade-up" className={`${styles.description} mt-4`}>
          VYNX GROUP OF COMPANIES helps businesses launch and expand across the globe with expertise in IT, Telecom, Education, and Company Formation services.
        </p>
      </div>
    </div>
  );
};

export default Banner;

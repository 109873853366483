import image1 from '../../assets/home/people01.png';
import image2 from '../../assets/home/people02.png';
import image3 from '../../assets/home/about3.png';

export const fakeData = [
    {
        id: 1,
        img: image1,
        name: 'Michael Brown',
        profession: 'Co-Founder, Tech Info',
        message: "The expertise from VYNX was essential in navigating the business formation process. Highly recommended!"
    },
    {
        id: 2,
        img: image2,
        name: 'John',
        profession: 'Founder, Eco Ventures',
        message: "Thanks to VYNX, my startup launched smoothly and without any hassle. Their support was invaluable throughout."
    },
    {
        id: 3,
        img: image3,
        name: 'Sarah Johnson',
        profession: 'CEO, Innovative Solutions',
        message: "VYNX Business Formation made starting my company incredibly easy. Their team guided me through every step."
    },
    {
        id: 4,
        img: image1,
        name: 'Michael Brown',
        profession: 'Co-Founder, Tech Info',
        message: "The VYNX team was extremely professional and helped me every step of the way. I couldn’t have asked for better assistance."
    },
    {
        id: 5,
        img: image2,
        name: 'John',
        profession: 'Founder, Eco Ventures',
        message: "Starting my business was a breeze with VYNX. Their seamless process made it quick and easy to get my company up and running."
    },
    {
        id: 6,
        img: image3,
        name: 'Sarah Johnson',
        profession: 'CEO, Innovative Solutions',
        message: "VYNX provided exceptional support during the business formation process. They made sure every detail was handled with care."
    },
    {
        id: 7,
        img: image1,
        name: 'David Williams',
        profession: 'Managing Director, Global Insights',
        message: "VYNX's professional approach made our business formation process hassle-free. Their support was crucial to our success."
    },
];
